import { tables } from "./common";
import { stash } from "./stash/stash";
import { useStash } from "@latticexyz/stash/react";
import { getRecord } from "@latticexyz/stash/internal";
import { useMemo } from "react";

export function usePlayers() {
  const alive = useStash(stash, (state) =>
    getRecord({ state, table: tables.AliveEntities, key: {} })
  );
  return useMemo(() => alive?.entities ?? [], [alive]);
}

import { stash } from "./stash/stash";
import { getRecord, getRecords } from "@latticexyz/stash/internal";
import { useStash } from "@latticexyz/stash/react";
import { useSessionClient } from "@latticexyz/entrykit/internal";
import { tables } from "./common";

export function useCurrentPlayer() {
  const { data: sessionClient } = useSessionClient();

  return useStash(stash, (state) => {
    const player = sessionClient?.userAddress;
    if (!player) return;

    const playerLower = player.toLowerCase();

    // Get all owner records in one go
    const ownerRecords = getRecords({ state, table: tables.Owner });
    const ownedIds = Object.values(ownerRecords)
      .filter(({ owner }) => owner.toLowerCase() === playerLower)
      .map(({ id }) => id);

    if (ownedIds.length === 0) return;

    // Get spawn times for owned characters
    let latestId = ownedIds[0];
    let latestTime = 0n;

    for (const id of ownedIds) {
      const spawnRecord = getRecord({
        state,
        table: tables.SpawnedAt,
        key: { id },
      });

      if (spawnRecord && spawnRecord.time > latestTime) {
        latestTime = spawnRecord.time;
        latestId = id;
      }
    }

    return latestId;
  });
}

import * as Dialog from "@radix-ui/react-dialog";
import { Leaderboard } from "./Leaderboard";
import { CharacterType } from "../common";
import { Blip } from "./Blip";
import { getEmojiUrl } from "../Emoji";
import { twMerge } from "tailwind-merge";

export type Props = {
  name: string;
  characterType: CharacterType;
  score: bigint;
};

export function PlayerScore({ name, characterType, score }: Props) {
  return (
    <Dialog.Root>
      <Dialog.Trigger
        className={twMerge(
          "outline-none",
          "bg-gradient-to-b from-lime-500 to-lime-600/60",
          "leading-none rounded-lg",
          "transition hover:brightness-110"
        )}
      >
        <span
          className="flex pl-16 rounded-lg border border-lime-300/40"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "6rem 6rem",
            backgroundPosition: "-1rem 0.25rem",
            backgroundImage: `url("${getEmojiUrl(characterType)}")`,
          }}
        >
          <span className="inline-flex flex-col items-start p-2">
            <span className="text-sm text-black font-medium">{name}</span>
            <span className="flex uppercase font-black text-white drop-shadow tabular-nums">
              <Blip on={score} className="scale-up-down-110" duration={150}>
                Score: {score.toString()}
              </Blip>
            </span>
          </span>
        </span>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 w-full h-full overflow-auto grid place-items-center ui-inset-p-8 bg-lime-600/80 animate-[fade-in_250ms_ease-out_both]">
          <Dialog.Content
            aria-describedby={undefined}
            className="outline-none space-y-4 p-4"
          >
            <Dialog.Title className="text-center text-3xl uppercase font-black text-white drop-shadow-lg">
              Leaderboard
            </Dialog.Title>
            <Leaderboard />
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

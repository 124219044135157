import { Chain, http } from "viem";
import { anvil, base, mainnet } from "viem/chains";
import { createWagmiConfig } from "@latticexyz/entrykit/internal";
import { rhodolite, garnet, redstone } from "@latticexyz/common/chains";
import { chainId } from "./common";

const chains = [
  mainnet,
  base,
  redstone,
  garnet,
  rhodolite,
  {
    ...anvil,
    rpcUrls: {
      ...anvil.rpcUrls,
      // TODO: automatically grant allowance in anvil instead of requiring the service
      bundler: {
        http: ["http://127.0.0.1:4337"],
      },
      quarryPassIssuer: {
        http: ["http://127.0.0.1:3003/rpc"],
      },
    },
    contracts: {
      // TODO: make optional in entrykit?
      quarryPaymaster: {
        address: "0x8D8b6b8414E1e3DcfD4168561b9be6bD3bF6eC4B",
      },
    },
  },
] as const satisfies Chain[];

const transports = {
  [mainnet.id]: http(),
  [base.id]: http(),
  [anvil.id]: http(),
  [garnet.id]: http(),
  [rhodolite.id]: http(),
  [redstone.id]: http(),
} as const;

const rp = location.origin.endsWith(".onquarry.com")
  ? {
      id: "onquarry.com",
      name: "Quarry",
    }
  : location.origin.endsWith(".tunnel.offchain.dev")
    ? {
        id: "tunnel.offchain.dev",
        name: "local tunnel",
      }
    : undefined;

export const wagmiConfig = createWagmiConfig({
  chainId,
  walletConnectProjectId: "14ce88fdbc0f9c294e26ec9b4d848e44",
  appName: document.title,
  chains,
  transports,
  pollingInterval: {
    [mainnet.id]: 12000,
    [base.id]: 2000,
    [anvil.id]: 500,
    [garnet.id]: 2000,
    [rhodolite.id]: 2000,
    [redstone.id]: 2000,
  },
  credentialOptions: { rp },
});

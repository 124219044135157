import { Direction, PositionData } from "./common";

export function getHeading(
  from: PositionData,
  to: PositionData,
  weighted: "vertical" | "horizontal"
): Direction {
  const [deltaX, deltaY] = [to.x - from.x, to.y - from.y];

  if (deltaX === 0) return deltaY > 0 ? "South" : "North";
  if (deltaY === 0) return deltaX > 0 ? "East" : "West";

  return weighted === "vertical"
    ? deltaY > 0
      ? "South"
      : "North"
    : deltaX > 0
      ? "East"
      : "West";
}

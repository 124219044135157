import { bigIntSort, isDefined, uniqueBy } from "@latticexyz/common/utils";
import { enums, tables } from "../common";
import { stash } from "../stash/stash";
import isEqual from "fast-deep-equal";
import { LeaderboardEntry } from "./LeaderboardEntry";
import { useStash } from "@latticexyz/stash/react";
import { getRecord, getRecords } from "@latticexyz/stash/internal";
import { groupBy } from "@ark/util";
import { usePlayerCharacter } from "../usePlayerCharacter";
import { getName } from "../names/getName";

export function Leaderboard() {
  const currentPlayer = usePlayerCharacter();

  const scores = useStash(
    stash,
    (state) => {
      const characters = Object.values(
        getRecords({ state, table: tables.Score })
      )
        .sort((a, b) => bigIntSort(b.score, a.score))
        .map((record) => {
          // filter out current player, we'll add em back later
          if (currentPlayer && record.id === currentPlayer.id) return;

          const character = getRecord({
            state,
            table: tables.Character,
            key: record,
          });
          if (character == null) return;
          const characterType = enums.CharacterType[character.characterType]!;

          const { owner } =
            getRecord({
              state,
              table: tables.Owner,
              key: record,
            }) ?? {};
          if (owner == null) return;
          const name = getName(owner, characterType);

          const { health } =
            getRecord({
              state,
              table: tables.Health,
              key: record,
            }) ?? {};
          if (health == null) return;

          return {
            ...record,
            name,
            characterType,
            owner,
            isAlive: health > 0n,
          };
        })
        .filter(isDefined);

      const uniqueNames = uniqueBy(characters, (character) => character.name);

      const groups = groupBy(uniqueNames, "characterType");

      const frogs = Object.values(groups.Frog ?? {});
      const flies = Object.values(groups.Fly ?? {});

      const topFrogs = [
        ...(currentPlayer?.characterType === "Frog" ? [currentPlayer] : []),
        ...frogs.filter((player) => player.isAlive).slice(0, 5),
        ...frogs.filter((player) => !player.isAlive).slice(0, 10),
      ]
        .slice(0, 10)
        .sort((a, b) => bigIntSort(b.score, a.score));

      const topFlies = [
        ...(currentPlayer?.characterType === "Fly" ? [currentPlayer] : []),
        ...flies.filter((player) => player.isAlive).slice(0, 5),
        ...flies.filter((player) => !player.isAlive).slice(0, 10),
      ]
        .slice(0, 10)
        .sort((a, b) => bigIntSort(b.score, a.score));

      return {
        aliveFrogs: topFrogs.filter((player) => player.isAlive),
        deadFrogs: topFrogs.filter((player) => !player.isAlive),
        aliveFlies: topFlies.filter((player) => player.isAlive),
        deadFlies: topFlies.filter((player) => !player.isAlive),
      };
    },
    { isEqual }
  );

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <div className="bg-white rounded-lg shadow-xl p-8 space-y-4">
        <div className="text-center uppercase font-black">Feasty frogs</div>
        <div>
          {scores.aliveFrogs.map((player) => (
            <LeaderboardEntry
              key={player.id}
              isCurrentPlayer={currentPlayer?.id === player.id}
              isCurrentOwner={currentPlayer?.owner === player.owner}
              {...player}
            />
          ))}
          {scores.deadFrogs.map((player) => (
            <LeaderboardEntry
              key={player.id}
              isCurrentPlayer={currentPlayer?.id === player.id}
              isCurrentOwner={currentPlayer?.owner === player.owner}
              {...player}
            />
          ))}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-xl p-8 space-y-4">
        <div className="text-center uppercase font-black">Fidgety flies</div>
        <div>
          {scores.aliveFlies.map((player) => (
            <LeaderboardEntry
              key={player.id}
              isCurrentPlayer={currentPlayer?.id === player.id}
              isCurrentOwner={currentPlayer?.owner === player.owner}
              {...player}
            />
          ))}
          {scores.deadFlies.map((player) => (
            <LeaderboardEntry
              key={player.id}
              isCurrentPlayer={currentPlayer?.id === player.id}
              isCurrentOwner={currentPlayer?.owner === player.owner}
              {...player}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

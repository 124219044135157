import { stash } from "./stash/stash";
import { Entity, tables, enums, CharacterType, Direction } from "./common";
import { isDefined } from "@latticexyz/common/utils";
import isEqual from "fast-deep-equal";
import { useStash } from "@latticexyz/stash/react";
import { getRecord } from "@latticexyz/stash/internal";
import { getName } from "./names/getName";
import { Address } from "viem";

export type Character = {
  readonly id: Entity;
  readonly name: string;
  readonly owner: Address;
  readonly characterType: CharacterType;
  readonly isAlive: boolean;
  readonly score: bigint;
  readonly x: number;
  readonly y: number;
  readonly heading: Direction;
};

export function useCharacters(ids: readonly Entity[]) {
  return useStash(
    stash,
    (state) =>
      ids
        .map((id): Character | undefined => {
          const character = getRecord({
            state,
            table: tables.Character,
            key: { id },
          });
          if (!character) return;
          const characterType = enums.CharacterType[character.characterType]!;

          const owner = getRecord({
            state,
            table: tables.Owner,
            key: { id },
          })?.owner;
          if (!owner) return;
          const name = getName(owner, characterType);

          const health = getRecord({
            state,
            table: tables.Health,
            key: { id },
          });
          if (!health) return;

          const score = getRecord({
            state,
            table: tables.Score,
            key: { id },
            defaultValue: { score: 0n },
          });

          const position = getRecord({
            state,
            table: tables.Position,
            key: { id },
          });
          if (!position) return;

          // TODO: check entity at position

          return {
            id,
            name,
            owner,
            characterType,
            isAlive: health.health > 0n,
            score: score.score,
            x: position.x,
            y: position.y,
            heading: enums.Direction[position.heading]!,
          };
        })
        .filter(isDefined),
    { isEqual }
  );
}

import { twMerge } from "tailwind-merge";
import { PlayButton } from "./PlayButton";

export function Dead() {
  return (
    <>
      <div
        className={twMerge(
          "fixed inset-0",
          "grid place-items-center",
          "backdrop-sepia bg-black/80",
          "animate-[fade-in_1s_ease-out_both]"
        )}
      >
        <div className="space-y-32 text-center">
          <div className="text-white text-7xl font-black uppercase opacity-0 animate-[fade-in_500ms_ease-out_1s_both]">
            u ded :(
          </div>
          <div className="opacity-0 animate-[fade-in_500ms_ease-out_1.25s_both]">
            <PlayButton />
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          "fixed inset-0",
          "pointer-events-none",
          "bg-red-700/90",
          "animate-[fade-out_750ms_ease-out_both]"
        )}
      />
    </>
  );
}

import { CharacterType } from "../common";
import { twMerge } from "tailwind-merge";
import { Emoji } from "../Emoji";

export type Props = {
  name: string;
  characterType: CharacterType;
  isAlive: boolean;
  score: bigint;
  isCurrentPlayer: boolean;
  isCurrentOwner: boolean;
};

export function LeaderboardEntry({
  name,
  characterType,
  isAlive,
  score,
  isCurrentPlayer,
  isCurrentOwner,
}: Props) {
  return (
    <div
      className={twMerge(
        "flex gap-4",
        !isAlive ? "opacity-50 text-red-600" : null,
        isCurrentPlayer
          ? "px-2 -mx-2 rounded bg-lime-100"
          : isCurrentOwner
            ? "px-2 -mx-2 rounded bg-yellow-200"
            : null
      )}
    >
      <span className="flex-grow flex gap-2 items-center font-medium">
        <Emoji
          type={characterType}
          className={twMerge(!isAlive ? "rotate-180" : null)}
        />
        <span>{name}</span>
      </span>
      <span className="flex-shrink-0 tabular-nums">
        {score.toLocaleString()}
      </span>
    </div>
  );
}

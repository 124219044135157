import { useStash } from "@latticexyz/stash/react";
import { stash } from "./stash/stash";
import { getRecords } from "@latticexyz/stash/internal";
import { tables } from "./common";
import { useEffect, useState } from "react";
import isEqual from "fast-deep-equal";

const corpseDuration = 1000 * 60 * 10;
const maxCorpses = 50; // Maximum number of corpses to show

export function useCorpses() {
  const [deadSince, setDeadSince] = useState(Date.now() - corpseDuration);
  useEffect(() => {
    const timer = setInterval(() => {
      setDeadSince(Date.now() - corpseDuration);
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  return useStash(
    stash,
    (state) =>
      Object.values(getRecords({ state, table: tables.DiedAt }))
        .filter(({ time }) => Number(time) * 1000 > deadSince)
        .sort((a, b) => Number(b.time) - Number(a.time)) // Sort by most recent death
        .slice(0, maxCorpses) // Limit the number of corpses
        .map(({ id }) => id),
    { isEqual }
  );
}

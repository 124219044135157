import { usePlayerCharacter } from "./usePlayerCharacter";
import { AccountButton } from "@latticexyz/entrykit/internal";
import { UIContainer } from "./UIContainer";
import { PlayButton } from "./PlayButton";
import { UILayer } from "./UILayer";
import { useSyncProgress } from "./stash/useSyncProgress";
import { PlayerScore } from "./score/PlayerScore";
import { Moves } from "./actions/Moves";
import { Dead } from "./Dead";
import { twMerge } from "tailwind-merge";

export function UI() {
  const isEmbedded = new URLSearchParams(window.location.search).has("embed");

  const { isLive } = useSyncProgress();
  const player = usePlayerCharacter();
  const commit = import.meta.env.VITE_GIT_COMMIT_SHA.substring(0, 7);

  return (
    <>
      <UIContainer>
        {isLive && player ? (
          <UILayer className="self-end justify-self-start *:pointer-events-auto">
            <PlayerScore {...player} />
          </UILayer>
        ) : null}

        {!isEmbedded ? (
          <UILayer className="self-start justify-self-end *:pointer-events-auto flex flex-col items-center">
            <div className="inline-flex pointer-events-auto">
              <AccountButton />
            </div>
            <span className="text-xs text-black/40">{commit || "local"}</span>
          </UILayer>
        ) : null}

        {!player ? (
          <UILayer
            className={twMerge(
              "place-self-center *:pointer-events-auto",
              "transition delay-500 duration-1000",
              isLive ? "opacity-100" : "opacity-0"
            )}
          >
            <PlayButton />
          </UILayer>
        ) : null}

        <UILayer className="place-self-end">
          <Moves />
        </UILayer>
      </UIContainer>

      {isLive && player && !player.isAlive ? <Dead /> : null}
    </>
  );
}

/// <reference types="vite-plugin-pwa/react" />
import { useRegisterSW } from "virtual:pwa-register/react";
import { UIContainer } from "./UIContainer";
import { UILayer } from "./UILayer";
import { useEffect, useState } from "react";
import { Button } from "./ui/Button";

export function AppUpdates() {
  const [registration, setRegistration] = useState<
    ServiceWorkerRegistration | undefined
  >();

  useEffect(() => {
    if (!registration) return;
    const timer = setInterval(() => {
      console.log("checking for PWA update (interval)");
      registration.update();
    }, 1000 * 60);
    function listener() {
      if (!document.hidden) {
        console.log("checking for PWA update (doc visible)");
        registration?.update();
      }
    }
    document.addEventListener("visibilitychange", listener);
    return () => {
      clearInterval(timer);
      document.removeEventListener("visibilitychange", listener);
    };
  }, [registration]);

  const {
    needRefresh: [needsRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    // immediate: import.meta.env.DEV,
    onRegisteredSW: (_url, registration) => {
      if (registration) {
        setRegistration(registration);
      }
    },
  });

  if (!needsRefresh) return null;

  return (
    <UIContainer>
      <UILayer className="justify-self-center *:pointer-events-auto">
        <Button
          className="mt-[20vh] bg-white shadow-xl rounded-md p-4 text-sm leading-none font-medium fade-in-100"
          onClick={() => updateServiceWorker(true)}
        >
          App updated! Reload?
        </Button>
      </UILayer>
    </UIContainer>
  );
}

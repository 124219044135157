import { twMerge } from "tailwind-merge";
import { CharacterType, TerrainType } from "./common";

export type EmojiType = CharacterType | TerrainType;

export function getEmojiUrl(type: EmojiType) {
  switch (type) {
    case "Frog":
    case "Fly":
    case "Tree":
    case "Boulder":
      return `/emoji/${type.toLowerCase()}.png`;
    default:
      return null;
  }
}

export type Props = {
  type: EmojiType;
  className?: string;
};

export function Emoji({ type, className }: Props) {
  const url = getEmojiUrl(type);
  if (!url) return null;
  return (
    <img
      src={url}
      className={twMerge(
        "w-[1em] h-[1em] pointer-events-none select-none touch-none",
        className
      )}
    />
  );
}

import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type Props = {
  className?: string;
  children: ReactNode;
};

export function UIContainer({ className, children }: Props) {
  return (
    <div
      className={twMerge(
        "absolute inset-0 grid w-full h-full overflow-hidden ui-inset-p-2",
        "pointer-events-none",
        className
      )}
    >
      {children}
    </div>
  );
}

import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type Props = {
  className?: string;
  children: ReactNode;
};

export function UILayer({ className, children }: Props) {
  return (
    <div
      className={twMerge(
        "col-start-1 row-start-1",
        "will-change-transform", // start new stacking context
        className
      )}
    >
      {children}
    </div>
  );
}

import { Hex } from "viem";
import worldAbi from "../out/IWorld.sol/IWorld.abi.json";
import mudConfig from "../mud.config";

export { mudConfig };
export type mudConfig = typeof mudConfig;

export const tables = mudConfig.namespaces.app.tables;
export type tables = typeof tables;

export const enums = mudConfig.enums;
export type enums = typeof enums;
export const enumValues = mudConfig.enumValues;
export type enumValues = typeof enumValues;

export type Direction = enums["Direction"][number];
export type CharacterType = enums["CharacterType"][number];
export type TerrainType = enums["TerrainType"][number];

export type Entity = Hex;

export type PositionData = { readonly x: number; readonly y: number };

export const chainId = parseInt(import.meta.env.VITE_CHAIN_ID) || 31337;

export { worldAbi };

export function scale(n: number) {
  return `${n * 6}vmax`;
}

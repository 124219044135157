import { Player } from "./Player";
import { useCorpses } from "./useCorpses";
import { useCurrentPlayer } from "./useCurrentPlayer";
import { usePlayers } from "./usePlayers";

export function Players() {
  const currentPlayer = useCurrentPlayer();
  const players = usePlayers();
  const corpses = useCorpses();

  return (
    <>
      {corpses.map((id) => (
        <Player key={id} id={id} currentPlayer={currentPlayer} />
      ))}

      {players.map((id) => (
        <Player key={id} id={id} currentPlayer={currentPlayer}/>
      ))}
    </>
  );
}

import { useCallback, useEffect, useRef, useState } from "react";
import { useKeyboardMovement } from "./useKeyboardMovement";
import { Grid, Astar } from "fast-astar";
import { clientDistance } from "./clientDistance";
import { getHeading } from "./getHeading";
import { PositionData, scale, tables, TerrainType } from "./common";
import { usePlayers } from "./usePlayers";
import { twMerge } from "tailwind-merge";
import { usePlayerCharacter } from "./usePlayerCharacter";
import { useMove } from "./useMove";
import { Terrain } from "./Terrain";
import { getRecord, subscribeTable } from "@latticexyz/stash/internal";
import { stash } from "./stash/stash";
import { Character } from "./useCharacters";
import { Players } from "./Players";

export type Props = {
  width: number;
  height: number;
  terrain: readonly TerrainType[];
};

export function Map({ width, height, terrain }: Props) {
  const playerContainerRef = useRef<HTMLDivElement | null>(null);
  const player = usePlayerCharacter();
  const players = usePlayers();
  const move = useMove(player?.id);
  const [taps, setTaps] = useState<{ id: string; x: number; y: number }[]>([]);

  const [appear, setAppear] = useState<string | null>(
    "animate-[fade-in_1s_ease-out_both]"
  );

  const [shakes, setShakes] = useState<number>(0);
  const [kills, setKills] = useState<number>(0);
  useEffect(
    () =>
      subscribeTable({
        stash,
        table: tables.DiedAt,
        subscriber: (updates) => {
          const death = Object.values(updates).find(
            (value) => value.prev == null && value.current != null
          )?.current;

          if (death) {
            setAppear(null);
            setShakes((count) => count + 1);
            setTimeout(() => setShakes((count) => count - 1), 100);
            if (death.killer === player?.id) {
              setKills((kills) => kills + 1);
              setTimeout(() => setKills((count) => count - 1), 250);
            }
          }
        },
      }),
    [player?.id]
  );

  useKeyboardMovement(player?.id ? move.mutateAsync : undefined);

  const center = player ?? {
    x: Math.floor(width / 2),
    y: Math.floor(height / 2),
  };

  const getNextMove = useCallback(
    (
      player: Character,
      to: PositionData,
      weighted: "vertical" | "horizontal"
    ) => {
      const grid = new Grid({ col: width, row: height });
      for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
          const tile = terrain.at(y * width + x);
          if (tile && tile !== "None") {
            grid.set([x, y], "value", 1);
          }
        }
      }

      if (player.characterType !== "Frog") {
        players.forEach((id) => {
          const position = getRecord({
            stash,
            table: tables.Position,
            key: { id },
          });
          if (position) {
            grid.set([position.x, position.y], "value", 1);
          }
        });
      }

      const astar = new Astar(grid);
      const path = astar.search([player.x, player.y], [to.x, to.y]);

      // path[0] is `from` position
      const nextMove = path?.[1];
      if (nextMove) {
        const [x, y] = nextMove;
        if (x != null && y != null) {
          const heading = getHeading(player, { x, y }, weighted);
          console.log(`next move: ${x}, ${y} ${heading}`);
          return heading;
        }
      }
    },
    [height, players, terrain, width]
  );

  return (
    <div
      className={twMerge(
        "absolute inset-0",
        "grid place-items-center",
        "overflow-clip",
        appear,
        shakes > 0 ? "animate-[shake_200ms_ease-out_both]" : null
      )}
      // TODO: move this somewhere else? maybe to UI?
      onPointerDown={async (event) => {
        if (!player) return;
        if (!playerContainerRef.current) return;

        const distance = clientDistance(
          playerContainerRef.current,
          event.clientX,
          event.clientY
        );
        const x = player.x + distance.x;
        const y = player.y + distance.y;

        if (x < 0 || x >= width || y < 0 || y >= height) return;

        setTaps((taps) => [...taps, { id: Date.now().toString(), x, y }]);

        const nextMove = getNextMove(player, { x, y }, distance.weighted);
        if (nextMove) {
          await move.mutateAsync(nextMove);
        }
      }}
    >
      <div
        className="col-start-1 row-start-1 relative transition-transform duration-[4s] ease-viewport"
        style={{
          transform: `translate(${scale(-center.x - 0.5)}, ${scale(
            -center.y - 0.5
          )})`,
        }}
      >
        <Terrain width={width} height={height} terrain={terrain} />

        {/* Used to translate map clicks to position */}
        {player ? (
          <div
            ref={playerContainerRef}
            className="absolute pointer-events-none opacity-0"
            style={{
              width: scale(1),
              height: scale(1),
              transform: `translateX(${scale(player.x)}) translateY(${scale(
                player.y
              )})`,
            }}
          />
        ) : null}

        <Players />

        {taps.map((tap) => (
          <div
            key={tap.id}
            className="absolute border-4 border-lime-400 animate-[fade-out_1s_ease-out_both] pointer-events-none"
            onAnimationEnd={() =>
              setTaps((taps) => taps.filter(({ id }) => id !== tap.id))
            }
            style={{
              width: scale(1),
              height: scale(1),
              left: scale(tap.x),
              top: scale(tap.y),
            }}
          />
        ))}
      </div>

      <div
        className={twMerge(
          "fixed inset-0",
          "pointer-events-none",
          "bg-yellow-400/90",
          kills > 0 ? "animate-[fade-out_250ms_ease-out_both]" : "opacity-0"
        )}
      />

      {/* <div className="col-start-1 row-start-1 absolute">
        <div
          className="border-4 border-blue-500/50"
          style={{
            width: scale(1),
            height: scale(1),
          }}
        />
      </div> */}
    </div>
  );
}

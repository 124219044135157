import { enums, tables } from "./common";
import { Map } from "./Map";
import { stash } from "./stash/stash";
import { useStash } from "@latticexyz/stash/react";
import { useSyncProgress } from "./stash/useSyncProgress";
import { UI } from "./UI";
import { UIContainer } from "./UIContainer";
import { useMemo } from "react";
import { isDefined } from "@latticexyz/common/utils";
import { getRecord } from "@latticexyz/stash/internal";
import { AppUpdates } from "./AppUpdates";

export function App() {
  const { isLive, message, percentage } = useSyncProgress();
  const map = useStash(stash, (state) =>
    getRecord({ state, table: tables.Map, key: {} })
  );
  const terrain = useMemo(
    () => map?.terrain.map((i) => enums.TerrainType.at(i)).filter(isDefined),
    [map?.terrain]
  );

  return (
    <>
      {!isLive ? (
        <UIContainer>
          <div className="self-end tabular-nums text-green-700">
            {message} ({percentage.toFixed(1)}%)…
          </div>
        </UIContainer>
      ) : !map || !terrain ? (
        <UIContainer>
          <div className="self-end tabular-nums text-green-700">
            Waiting for map…
          </div>
        </UIContainer>
      ) : (
        <Map width={map.width} height={map.height} terrain={terrain} />
      )}

      <UI />

      {/* Always leave this last */}
      <AppUpdates />
    </>
  );
}

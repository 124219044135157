import { twMerge } from "tailwind-merge";
import {
  useAccountModal,
  useSessionClient,
} from "@latticexyz/entrykit/internal";
import { useSpawn } from "./useSpawn";
import { getEmojiUrl } from "./Emoji";
import { AsyncButton } from "./ui/AsyncButton";

export function PlayButton() {
  const spawn = useSpawn();
  const sessionClient = useSessionClient();
  const { openAccountModal } = useAccountModal();

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <div className="text-3xl text-white font-black uppercase drop-shadow">
        Frog or Fly?
      </div>
      <div className="flex items-center gap-2">
        <AsyncButton
          className={twMerge(
            "group outline-none relative",
            "w-28 h-28 rounded-full bg-teal-500 shadow-2xl shadow-black/50 border-[.5rem] border-black/10",
            "transition hover:scale-110 hover:brightness-110 active:scale-100 active:brightness-90",
            "aria-busy:opacity-50"
          )}
          pending={spawn.isPending}
          onClick={() =>
            sessionClient.data ? spawn.mutateAsync("Frog") : openAccountModal()
          }
        >
          <span
            className="absolute -inset-2 rounded-full transition rotate-90 group-hover:rotate-0"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "125%",
              backgroundPosition: "50% 1.5rem",
              backgroundImage: `url("${getEmojiUrl("Frog")}")`,
            }}
          ></span>
          <span className="sr-only">Fly</span>
        </AsyncButton>
        <AsyncButton
          className={twMerge(
            "group outline-none relative",
            "w-28 h-28 rounded-full bg-green-500 shadow-2xl shadow-black/50 border-[.5rem] border-black/10",
            "transition hover:scale-110 hover:brightness-110 active:scale-100 active:brightness-90",
            "aria-busy:opacity-50"
          )}
          pending={spawn.isPending}
          onClick={() =>
            sessionClient.data ? spawn.mutateAsync("Fly") : openAccountModal()
          }
        >
          <span
            className="absolute -inset-2 rounded-full transition -rotate-90 group-hover:rotate-0"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "125%",
              backgroundPosition: "50% 1.5rem",
              backgroundImage: `url("${getEmojiUrl("Fly")}")`,
            }}
          ></span>
          <span className="sr-only">Fly</span>
        </AsyncButton>
      </div>
    </div>
  );
}

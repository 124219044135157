import { Hex } from "viem";
import { createStore } from "zustand/vanilla";
import { Direction } from "../common";

export enum ActionStatus {
  submitted,
  accepted,
  reverted,
  confirmed,
  applied,
}

export type ActionInput = {
  type: "move";
  direction: Direction;
};

export type Action = {
  id: string;
  createdAt: Date;
  status: ActionStatus;
  input: ActionInput;
  transactionHash?: Hex;
  appliedAt?: Date;
};

export const store = createStore<{ actions: { [id: string]: Action } }>(() => ({
  actions: {},
}));

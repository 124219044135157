import { useMutation } from "@tanstack/react-query";
import { Direction, Entity, enums } from "./common";
import { useWorldContract } from "./useWorldContract";
import { ActionStatus, store as actionStore } from "./actions/store";
import { getNonce } from "./getNonce";

let moveCounter = 0;

export function useMove(id: Entity | undefined) {
  const { worldContract } = useWorldContract();
  const mutationKey = ["move", worldContract?.address, id];
  return useMutation<void, Error, Direction>({
    mutationKey,
    async mutationFn(direction) {
      if (!worldContract) {
        throw new Error("World contract not ready. Are you connected?");
      }
      if (!id) {
        throw new Error("No entity ID provided. Are you connected?");
      }

      console.log("submitting move", direction);

      const actionId = `move:${++moveCounter}`;
      actionStore.setState((state) => ({
        actions: {
          ...state.actions,
          [actionId]: {
            id: actionId,
            createdAt: new Date(),
            status: ActionStatus.submitted,
            input: {
              type: "move",
              direction,
            },
          },
        },
      }));

      const tx = await worldContract.write.app__move(
        [id, enums.Direction.indexOf(direction)],
        { nonce: Number(getNonce()) }
      );

      actionStore.setState((state) => ({
        actions: {
          ...state.actions,
          [actionId]: {
            ...state.actions[actionId]!,
            status: ActionStatus.accepted,
          },
        },
      }));

      // console.log("waiting for move receipt", tx);
      // const receipt = await waitForTransaction(tx);

      // if (receipt.status === "reverted") {
      //   actionStore.setState((state) => ({
      //     actions: {
      //       ...state.actions,
      //       [actionId]: {
      //         ...state.actions[actionId]!,
      //         status: ActionStatus.reverted,
      //       },
      //     },
      //   }));
      //   throw new Error("Move reverted");
      // }

      actionStore.setState((state) => ({
        actions: {
          ...state.actions,
          [actionId]: {
            ...state.actions[actionId]!,
            status: ActionStatus.applied,
            appliedAt: new Date(),
          },
        },
      }));

      // console.log("move done", receipt);
      console.log("move done", tx);
    },
  });
}

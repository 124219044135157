export function clientDistance(
  element: Element,
  clientX: number,
  clientY: number
) {
  const clientRect = element.getBoundingClientRect();
  const offsetX = clientX - clientRect.left;
  const offsetY = clientY - clientRect.top;
  return {
    x: Math.floor(offsetX / clientRect.width),
    y: Math.floor(offsetY / clientRect.height),
    weighted: Math.abs(offsetX) > Math.abs(offsetY) ? "horizontal" : "vertical",
  } as const;
}

import { useMutation } from "@tanstack/react-query";
import { CharacterType, enumValues } from "./common";
import { useWorldContract } from "./useWorldContract";

export function useSpawn() {
  const { worldContract, waitForTransaction } = useWorldContract();

  return useMutation<void, Error, CharacterType>({
    onError: (error) => console.error(error),
    mutationKey: ["spawn", worldContract?.address],
    mutationFn: async (characterType) => {
      if (!worldContract) {
        throw new Error("World contract not ready. Are you connected?");
      }

      console.log("spawn", characterType);
      const tx = await worldContract.write.app__spawn([
        enumValues.CharacterType[characterType],
      ]);
      console.log("spawning", tx);

      const receipt = await waitForTransaction(tx);
      console.log("spawned", receipt);
    },
  });
}

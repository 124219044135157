import { scale, TerrainType } from "./common";
import { Emoji } from "./Emoji";

export type Props = {
  width: number;
  height: number;
  terrain: readonly TerrainType[];
};

export function Terrain({ width, height, terrain }: Props) {
  return (
    <>
      <div
        className="absolute bg-lime-500 shadow-[0_0_10vmax_0_var(--tw-shadow-color)] shadow-lime-700"
        style={{
          width: scale(width),
          height: scale(height),
          left: 0,
          top: 0,
        }}
      />
      {terrain.map((terrainType, i) => {
        if (terrainType === "None") return null;
        const x = i % width;
        const y = (i - x) / width;
        return (
          <div
            key={[x, y].join(",")}
            className="absolute grid place-items-center"
            style={{
              width: scale(1),
              height: scale(1),
              left: scale(x),
              top: scale(y),
              fontSize: scale(1),
              lineHeight: "1",
            }}
          >
            <Emoji type={terrainType} />
          </div>
        );
      })}
    </>
  );
}

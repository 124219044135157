import { defineWorld } from "@latticexyz/world";

export default defineWorld({
  sourceDirectory: "contracts",
  namespace: "app",
  userTypes: {
    Entity: { type: "bytes32", filePath: "./contracts/Entity.sol" },
  },
  enums: {
    Direction: ["North", "East", "South", "West"],
    CharacterType: ["Fly", "Frog"],
    TerrainType: ["None", "Tree", "Boulder"],
  },
  tables: {
    // config/global state
    EntityCount: {
      schema: {
        count: "uint256",
      },
      key: [],
    },
    Map: {
      schema: {
        width: "uint32",
        height: "uint32",
        terrain: "uint8[]",
      },
      key: [],
    },
    // entities
    Owner: {
      id: "Entity",
      owner: "address",
    },
    Character: {
      id: "Entity",
      characterType: "CharacterType",
    },
    Position: {
      id: "Entity",
      x: "uint32",
      y: "uint32",
      heading: "Direction",
    },
    Health: {
      id: "Entity",
      health: "uint256",
    },
    Score: {
      id: "Entity",
      score: "uint256",
    },
    SpawnedAt: {
      id: "Entity",
      time: "uint256",
    },
    DiedAt: {
      id: "Entity",
      time: "uint256",
      killer: "Entity",
    },
    // indexes
    EntityAtPosition: {
      schema: { x: "uint32", y: "uint32", entity: "Entity" },
      key: ["x", "y"],
    },
    AliveEntities: {
      schema: { entities: "bytes32[]" },
      key: [],
    },
    AliveEntitiesIndex: {
      id: "Entity",
      index: "uint256",
    },
  },
  modules: [
    {
      artifactPath:
        "@latticexyz/world-modules/out/Unstable_CallWithSignatureModule.sol/Unstable_CallWithSignatureModule.json",
      root: true,
    },
  ],
});

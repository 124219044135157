import { ReactNode, useEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";

// this seems to stop working if rerender is triggered but `on` didn't change?

export type Props = {
  on: unknown;
  className: string;
  duration: number;
  children: ReactNode;
};

export function Blip({ on, className, duration, children }: Props) {
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const [blips, setBlips] = useState(0);
  useEffect(() => {
    setBlips((blips) => blips + 1);
    setTimeout(() => {
      if (mountedRef.current) {
        setBlips((blips) => blips - 1);
      }
    }, duration);
  }, [duration, on]);

  return (
    <span className={twMerge(blips > 0 ? className : null)}>{children}</span>
  );
}

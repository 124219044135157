import { twMerge } from "tailwind-merge";
import { stringify } from "viem";
import { scale, Entity } from "./common";
import { useCharacters } from "./useCharacters";
import { useMemo } from "react";
import { Emoji } from "./Emoji";
import { Blip } from "./score/Blip";

export type Props = {
  id: Entity;
  currentPlayer: Entity | undefined;
};

export function Player({ id, currentPlayer }: Props) {
  const [player] = useCharacters(useMemo(() => [id], [id]));

  const position = useMemo(
    () => [player?.x, player?.y],
    [player?.x, player?.y]
  );

  if (!player) return null;

  if (player.characterType === "Frog") {
    return (
      <div
        className={twMerge(
          "absolute grid place-items-center transition delay-50"
        )}
        style={{
          width: scale(1),
          height: scale(1),
          fontSize: scale(0.9),
          lineHeight: "1",
          transform: `translateX(calc(${scale(
            player.x
          )})) translateY(calc(${scale(player.y)}))`,
        }}
        title={stringify(player, null, 2)}
      >
        <Blip
          on={position}
          duration={150}
          className={twMerge(
            player.heading === "North"
              ? "animate-[frog-hop-north_150ms_ease-out_both]"
              : player.heading === "South"
                ? "animate-[frog-hop-south_150ms_ease-out_both]"
                : player.heading === "East"
                  ? "animate-[frog-hop-east_150ms_ease-out_both]"
                  : "animate-[frog-hop-west_150ms_ease-out_both]"
          )}
        >
          {/* TODO: consider rotation direction so East -> South does a 90 degree turn instead of 270 degree */}
          <Emoji
            type={player.characterType}
            className={twMerge(
              !player.isAlive
                ? "saturate-0 brightness-0 opacity-5"
                : player.id !== currentPlayer
                  ? "hue-rotate-60"
                  : null,
              "transition",
              player.heading === "East"
                ? "rotate-90"
                : player.heading === "South"
                  ? "rotate-180"
                  : player.heading === "West"
                    ? "-rotate-90"
                    : "rotate-0"
            )}
          />
        </Blip>
        {player.isAlive && player.id !== currentPlayer ? (
          <span className="absolute top-full text-xs leading-none -mt-1 text-black/40 px-1 py-0.5 rounded bg-lime-500/80">
            {player.name}
          </span>
        ) : null}
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        "absolute grid place-items-center transition delay-50"
      )}
      style={{
        width: scale(1),
        height: scale(1),
        fontSize: scale(0.6),
        lineHeight: "1",
        transform: `translateX(calc(${scale(
          player.x
        )})) translateY(calc(${scale(player.y)}))`,
      }}
      title={stringify(player, null, 2)}
    >
      {/* TODO: consider rotation direction so East -> South does a 90 degree turn instead of 270 degree */}
      <Emoji
        type={player.characterType}
        className={twMerge(
          !player.isAlive
            ? "saturate-0 brightness-0 opacity-5"
            : player.id !== currentPlayer
              ? "hue-rotate-60"
              : null,
          "transition-all",
          player.heading === "East"
            ? "rotate-90"
            : player.heading === "South"
              ? "rotate-180"
              : player.heading === "West"
                ? "-rotate-90"
                : "rotate-0"
        )}
      />
      {player.isAlive && player.id !== currentPlayer ? (
        <span className="absolute top-full text-xs leading-none -mt-1 text-black/40 px-1 py-0.5 rounded bg-lime-500/80">
          {player.name}
        </span>
      ) : null}
    </div>
  );
}

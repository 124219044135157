import { useStore } from "zustand/react";
import { ActionStatus, store } from "./store";
import { useMemo } from "react";
import { ArrowDownIcon } from "../ui/icons/ArrowDownIcon";
import { twMerge } from "tailwind-merge";
import { PendingIcon } from "../ui/icons/PendingIcon";

export function Moves() {
  const actions = useStore(store, (state) => state.actions);
  const moves = useMemo(() => {
    return Object.values(actions)
      .filter((action) => action.input.type === "move")
      .slice(-25);
  }, [actions]);

  return (
    <div className="flex flex-col justify-end gap-1 overflow-hidden">
      {moves.map((action) => (
        <div
          key={action.id}
          className={twMerge(
            "flex gap-1 items-center justify-end",
            "transition",
            action.status >= ActionStatus.accepted
              ? "opacity-100 fade-out-100 [animation-duration:1s] [animation-delay:3s]"
              : "opacity-40"
          )}
        >
          <span className="grid place-items-end text-xs leading-none">
            <span
              className={twMerge(
                "col-start-1 row-start-1 text-black/40",
                "transition",
                action.appliedAt ? "opacity-100" : "opacity-0"
              )}
            >
              {action.appliedAt ? (
                <>{action.appliedAt.getTime() - action.createdAt.getTime()}ms</>
              ) : null}
            </span>
            <span className="col-start-1 row-start-1">
              <PendingIcon
                className={twMerge(
                  "m-0 transition",
                  action.status <= ActionStatus.accepted
                    ? "opacity-100"
                    : "opacity-0"
                )}
              />
            </span>
          </span>
          <span
            className={twMerge(
              "text-xs leading-none rounded text-white w-[1.25em] h-[1.25em] grid place-items-center transition",
              action.status === ActionStatus.reverted
                ? "bg-red-600/80"
                : action.status === ActionStatus.confirmed
                  ? "bg-green-700/80"
                  : action.status === ActionStatus.applied
                    ? "bg-blue-600/80"
                    : "bg-neutral-600/80"
            )}
          >
            <ArrowDownIcon
              className={twMerge(
                action.input.direction === "East"
                  ? "-rotate-90"
                  : action.input.direction === "North"
                    ? "rotate-180"
                    : action.input.direction === "West"
                      ? "rotate-90"
                      : "rotate-0"
              )}
            />
          </span>
        </div>
      ))}
    </div>
  );
}

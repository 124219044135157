import { Address, Hex, hexToBigInt, keccak256 } from "viem";
import { CharacterType } from "../common";
import flyData from "./fly.json";
import frogData from "./frog.json";

export function getName(owner: Address, characterType: CharacterType): string {
  const ownerLower = owner.toLowerCase() as Hex;

  // vdrg bot
  if (ownerLower === "0x62954c2c9983611408103cd3514b68d0cf553c8b") {
    return characterType === "Frog" ? "GOROK" : "R5-D4";
  }

  // yeoman AI bots
  if (ownerLower === "0x816ac4da434be034bb3bde72a3bda9d307298a47") {
    return "Y-JustAFly";
  }
  if (ownerLower === "0xcca175142a2fc38f740ef61eb1c4a7c4269761bf") {
    return "Y-SmartFly";
  }
  if (ownerLower === "0xe169cbb7e25e2aba445e4a6939250b422aef9907") {
    return "Y-LazyFrog";
  }
  if (ownerLower === "0x304f42d454d06d5d8aa6d743c79039ce90dfad0a") {
    return "Y-HungryFrog";
  }

  const rand = hexToBigInt(keccak256(ownerLower));
  switch (characterType) {
    case "Frog":
      return `${frogData.prefixes[Number(rand % BigInt(frogData.prefixes.length))]}${frogData.suffixes[Number(rand % BigInt(frogData.suffixes.length))]}`;
    case "Fly":
      return `${flyData.prefixes[Number(rand % BigInt(flyData.prefixes.length))]}${flyData.suffixes[Number(rand % BigInt(flyData.suffixes.length))]}`;
  }
}
